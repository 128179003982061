
import Vue from "vue";

import { Breadcrumb, Member } from "@/interfaces";

import { Route } from "vue-router";
import http from "@/http";

export interface DataType {
  member?: Member;
}

export default Vue.extend({
  data(): DataType {
    return {
      member: undefined
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.member == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "契約者・入居者",
          disabled: false,
          to: "/members"
        },
        {
          text: `# ${this.member.id} ${this.member.name}`,
          disabled: true,
          to: `/member/${this.member.id}`
        }
      ];
    }
  },
  watch: {
    async $route() {
      const url = `/members/${this.$route.params.id}`;

      const { data } = await http.get<Member>(url);

      this.member = data;
    }
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/members/${to.params.id}`;

    const { data } = await http.get<Member>(url);

    next((vm: DataType & Vue) => {
      vm.member = data;
    });
  }
});
